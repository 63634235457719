.container {
  width: 100%;
  background: black;
  display: flex;
  color: white;
  flex-direction: column;
}

.topTitle {
  font-family: "MonumentExtended-Regular";
  font-size: 36px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
}

.social_container {
  width: 100%;
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.social_container_mobile {
  width: 100%;
  display: none;
  color: white;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.header_container {
  width: 100%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30px;
}

.swapArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}

.coinmarketcap {
  width: 212px;
  height: 36px;
}

.coingecko {
  width: 175px;
  height: 43px;
}

.uniswap {
  width: 160px;
  height: 40px;
}

.pancakeswap {
  width: 227px;
  height: 38px;
}

.quickswap {
  width: 164px;
  height: 39px;
}

.dappradar {
  width: 176px;
  height: 29px;
}

.ticketmaster {
  width: 174px;
  height: 26px;
}

.xsens {
  width: 192px;
  height: 31px;
}

.manux {
  width: 246px;
  height: 76px;
}

.polygon {
  width: 208px;
  height: 39px;
}

.book {
  width: 166px;
  height: 43px;
}

.nfl {
  width: 69px;
  height: 94px;
}

.bonfire {
  width: 114px;
  height: 115px;
}

.j {
  width: 120px;
  height: 115px;
}

.unreal {
  width: 90px;
  height: 116px;
}

.unity {
  width: 163px;
  height: 60px;
}

.socialButtonArea {
  display: flex;
  flex-direction: row;
}

.socialImage {
  width: 40px;
  margin: 0 30px;
}

.social_item {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.social_item:hover {
  cursor: pointer;
}

.first_social_item {
  display: flex;
  flex-direction: colum;
  font-size: 24px;
  color: white;
  font-weight: bold;
  font-family: MonumentExtended-Regular;
  letter-spacing: 3px;
}

.link_social_item {
  margin-top: 10px;
  font-size: 18px;
  color: #fff;
  font-family: MonumentExtended-Regular;
}

.pay_social_item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.pay_icon {
  width: 55px;
  height: 31px;
  margin-top: 10px;
  margin-right: 20px;
}

.sombra_mail {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spencer_icon {
  margin-top: 10px;
  width: 34px;
  height: 34px;
}

.spencer_label {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 100;
  color: #dc00fc;
}

.tweet_social_item {
  margin-top: 10px;
  font-weight: 100;
  font-size: 14px;
  width: 260px;
  color: #92b8e9;
}

.privacy_container {
  width: 100%;
  height: 93px;
  display: flex;
  align-items: center;
}

.copyright {
  margin-left: 3vw;
  font-weight: 100;
  font-size: 14px;
  color: #92b8e9;
}

.termpolicy {
  margin-left: auto;
  margin-right: 3vw;
  display: flex;
}

.termpolicyText {
  font-weight: 100;
  font-size: 14px;
  color: #92b8e9;
  margin-right: 5px;
}

.newsletterSection {
  width: 50vw;
  font-weight: 100;
}

.linksSection {
  width: 50vw;
}

.logoImg {
  opacity: 30%;
}

.logoImg img {
  opacity: 30%;
}

.listContainer {
  justify-content: left;
  padding-right: 9vw;
}

.listContainer ul {
  list-style-type: none;
  /* padding: 20px 0px 0px 180px; */
  padding-top: 20px;
  font-weight: 100;
  font-size: 14px;
}

.listContainer li {
  padding: 3px;
}

.bnb_smbr {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-bottom: -85px;
}

.logo_icon {
  width: 20px;
  height: 21px;
  margin-left: 20px;
}

.polygon_icon {
  width: 101px;
  height: 102px;
}

.bnb_icon {
  width: 78px;
  height: 76px;
}

.ethe_icon {
  width: 130px;
}

.buyBtn {
  width: 200px;
}

.bidButton {
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 2px;
  border-width: 3px;
  border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right,
      #d900f9 20%,
      #d500f8 60%,
      #4200ba 105%,
      #390063 120%);
  opacity: 0.5f;
  /* filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51)); */
  cursor: pointer;
}

.bidButtonLabel {
  display: flex;
  font-family: "Orbitron";
  font-size: max(12px, 0.7vw);
  text-align: center;
  transition: all ease-in-out 0.2s;
  color: white;
  letter-spacing: 1px;
  white-space: nowrap;
}

.bidButton:hover {
  /* color: rgba(56, 52, 52, 0.877);
    background: rgb(200, 200, 200); */
  opacity: 0.65;
}

@media (max-width: 1400px) {
  .listContainer {
    padding-right: "5px";
  }
}

.newsLetterForm {
  margin-top: 20px;
  margin-left: 10px;
  padding: 10px;
  padding-bottom: 13px;
  background: black;
  border: 1px solid #eeeeeeaf;
  border-radius: 14px;
}

@media (max-width: 1400px) {
  .listContainer ul {
    padding: 10px 0px 0px 40px;
  }
}

.newsLetterForm input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: black;
  color: white;
}

.newsLetterForm label {
  font-size: 12px;
  padding-left: 5px;
}

.newsLetterForm laebl {
  outline: none;
}

.newsLetterContainer {
  display: flex;
  margin-top: 70px;
  margin-left: 120px;
}

.subscribe {
  font-size: 12px;
  padding-left: 5px;
}

.mobileVisible {
  display: none;
}

.buttonArea {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  .social_container {
    flex-direction: column;
  }
}
@media (max-width: 1060px) {
  .mobileVisible {
    display: flex;
    flex-direction: column;
  }

  .desktopVisible {
    display: none;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    display: block;
  }

  .desktopVisible {
    display: none;
  }

  .mobileVisible {
    display: flex;
    flex-direction: column;
  }

  .header_container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .social_container {}

  .social_container_mobile {
    display: flex;
    height: 300px;
  }

  .swapArea {
    width: 100%;
    margin-top: 30px;
  }

  .coinmarketcap {
    width: 150px;
    height: 20px;
  }

  .coingecko {
    width: 100px;
    height: 20px;
  }

  .dappradar {
    width: 100px;
    height: 15px;
  }

  .uniswap {
    width: 90px;
    height: 22px;
  }

  .pancakeswap {
    width: 150px;
    height: 23px;
  }

  .quickswap {
    width: 100px;
    height: 22px;
  }

  .nfl {
    width: 55px;
    height: 80px;
  }

  .bonfire {
    width: 70px;
    height: 70px;
  }

  .j {
    width: 70px;
    height: 65px;
  }

  .unreal {
    width: 60px;
    height: 86px;
  }

  .unity {
    width: 123px;
    height: 40px;
  }

  .social_item {
    margin-left: 0%;
    margin-right: 0px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .first_social_item {
    font-size: 20px;
    color: white;
  }

  .link_social_item {
    margin-top: 10px;
    font-size: 14px;
  }

  .bidButton {
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
    border-width: 1px;
    width: 70px;
  }

  .bidButtonLabel {
    font-size: max(8px, 0.7vw);
  }

  .ethe_icon {
    width: 90px;
  }
  .buyBtn {
    width: 150px;
  }
  .bnb_smbr {
    margin-bottom: -60px;
  }
  .socialImage {
    width: 40px;
    margin: 0 20px;
  }
  .polygon_icon {
    width: 60px;
    height: 61px;
  }

  .bnb_icon {
    width: 78px;
    height: 76px;
  }

  .linksSection {
    width: 80vw;
  }

  .linksSection ul li {
    padding: 5px;
  }

  .newsletterSection {
    display: none;
  }

  .listContainer ul {
    padding: 2px 0px 0px 18px;
  }

  .listContainer {
    padding-right: 0;
  }

  .social_mobile_container {
    width: 100%;
    height: 285px;
    background: rgba(32, 8, 59, 0.75);
    backdrop-filter: blur(167px);
    display: flex;
    flex-direction: column;
    color: white;
  }

  .pay_bnb_icon {
    width: 36px;
    height: 36px;
    margin-top: 10px;
    margin-right: 20px;
  }

  .tweet_social_item {
    width: 100%;
  }

  .topTitle {
    font-size: 20px;
    letter-spacing: 0em;
  }
}