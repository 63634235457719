body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
html,
li,
p,
input {
  font-family: "Karla-Regular", sans-serif;
}

.loadingBar {
	background-color: white;
	margin-left: 0;
	margin-top: 15px;
}
.loadingArea{
	position: absolute;
	align-items: center;
	bottom: 50px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.wrapper{
  width: 100%;
  background-image: url(./assets/images/background1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  color: whitesmoke;
  font-size: 16px;
  padding-bottom:50px;
}

.collections_header_bg {
  width: 100%;
  height: 250px;
  filter: blur(100px);
  position: absolute;
}

.policyContent {
  display: flex;
  flex-direction: column;
  padding-left: 6vw;
  padding-right: 6vw;
  position: relative;
  padding-top: 50px;
}

.wrapper h1{
  font-family: 'Orbitron-Regular';
  font-size: max(2vw, 24px);
  font-weight: bold;
  color: white;
  letter-spacing: 0.22rem;
  padding-bottom: 20px;
}

.wrapper h2{
  font-family: 'Orbitron-Regular';
  font-size: max(1.4vw, 18px);
  font-weight: bold;
  color: white;
  letter-spacing: 0.22rem;
}

.termsWrapper{
  width: 80%;
  max-width: 1000px;
  margin: auto;
  color: whitesmoke;
  font-size: 3vw;
  padding-bottom: 100px;
}

.termsWrapper h1{
  text-align: center;
  font-size: 4vw;
  padding-bottom: 50px;
}

@media (max-width: 600px){
  .termsWrapper h1{
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .policyContent {
    margin-top: 0px;
  }
}

.termsWrapper p{
  padding-bottom: 10px;
}

.termsWrapper li{
  padding-bottom: 5px;
}

.MuiDialog-paperFullWidth {
  width: 100% !important;
}

.MuiDialog-paper {
  margin: 20px !important;
}
.MuiPaper-root{
  background-color: transparent !important;
}
.MuiPaper-elevation24{
  box-shadow: none !important;
}